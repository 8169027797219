import {numberToWords, removeCommas} from "@persian-tools/persian-tools";

export const strLimit = (value, length) => {
    return String(value).length <= length
        ? value
        : value.substr(0, length) + '...'
}

export function updateUrlQueries(path, params) {
    history.replaceState(
        {},
        null,
        '#' + path +
        '?' +
        Object.keys(params)
            .map(key => {
                const value = params[key] && JSON.stringify(params[key]);
                return (
                    encodeURIComponent(key) + '=' + encodeURIComponent(value)
                )
            })
            .join('&')
    )
}

export function getDataFromUrl(route) {
    const data = route.query || {};
    const queryData = {};
    const normalize = (value) => {
        if (value === 'true') {
            return true;
        }
        if (value === 'false') {
            return false;
        }
        if (value === 'null') {
            return null;
        }
        const reg = /^\d+$/;
        if (RegExp(reg).test(value)) {
            return Number(value);
        }

        try{
            return JSON.parse(value);
        }catch(e){
            return value;
        }

      
    }

    for (const [key, value] of Object.entries(data)) {
        queryData[key] = normalize(value);
    }
    return queryData;
}

export const convertNumberToWords = (number = null) => {
    if (number) {
        return String(numberToWords(removeCommas(String(number)))) + ' تومان ';
    }

}


