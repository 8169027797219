import axios from "axios"
import store from "../../../store";
import router from "../../../router";
import Vue from "vue";

const httpProvider = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    credentials: false,
    debug: false,
    https: true,
    host: '',
    prefix: '',
    port: '',
    headers: {
        common: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Cache-Control': 'no-cache'
        }
    },

});

httpProvider.interceptors.request.use(
    (config) => {
        const notAllowUrls = ['/basicinfo', '/info', '/Permissioninformation','/delPayment'];
        if (!notAllowUrls.includes(config.url)) {
            config.headers.Authorization = `Bearer ${store.getters['User/getToken']}`;
        }
        console.log({config});
        return config;
    },
    (error) => {
        return Promise.reject(error);
    });


httpProvider.interceptors.response.use(
    (response) => {
        console.log({response});
        return response;
    },
    (error) => {

        const errorResponse = error?.response?.data || {
            message: 'خطای رخ داده است.',
        }
        const statusCode = error?.response?.status || 500;

        switch (statusCode) {
            case 500: {
                Vue.$toast.error('خطایی رخ داده لطفا بعدا امتحان کنید.');
                break;
            }
            case 422: {
                let errors = Object.keys(errorResponse?.data?.errors).map((key) => {
                    return errorResponse?.data?.errors[key][0];
                });
                if (errors.length) {
                    Vue.$toast.error(errors[0]);
                }

                break;
            }
            case 403: {
                Vue.$toast.warning('شما دسترسی ندارید',);
                break;
            }
            case 401: {
                Vue.$toast.error('لطفا ابتدا وارد حساب کاربری خود شوید');
                router.replace({
                    path: '/auth/login',
                    query: {
                        redirect: router.currentRoute.path
                    }
                })
                break;
            }
            case 400:
            case 408: {
                Vue.$toast.error(errorResponse?.message || 'درخواست نامعتبر!',);
                break;
            }
            default: {
                //نت نداری !
                Vue.$toast.warning('خطایی رخ داده لطفا بعدا امتحان کنید.',);
            }

        }

        return Promise.reject(error);
    });


export default httpProvider;