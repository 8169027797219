import {httpProvider} from "@Newfiling/Services"

const appConfig = async () => {
    const serverVersion = (await httpProvider.get('/info'))?.data?.version;
    const localVersion = localStorage.getItem('version');

    if (serverVersion === localVersion) {
        if (localStorage.getItem('app-config')) {
            return JSON.parse(localStorage.getItem('app-config'));
        }
        const res = (await httpProvider.get('/basicinfo'))?.data || {}
        localStorage.setItem('app-config', JSON.stringify(res))
        return res;
    } else {
        localStorage.setItem('version', serverVersion);
        const res = (await httpProvider.get('/basicinfo'))?.data || {}
        localStorage.setItem('app-config', JSON.stringify(res))
        return res;
    }

}

export default appConfig();
